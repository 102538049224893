import React from 'react'
import Layout from '../components/layout'
import LegalWrapper from '../components/legal/legalWrapper'

const metaData = {
  title: 'Terms & Conditions | D E W Y N T E R S',
}

const TermsAndConditions = () => (
  <Layout>
    <LegalWrapper title={metaData.title}>
      <section>
        <h2>Introduction</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam corporis,
          impedit vitae sed natus quod tempore fugit facilis, consectetur
          excepturi distinctio vel! Necessitatibus, facilis iusto.
        </p>
      </section>
    </LegalWrapper>
  </Layout>
)

export default TermsAndConditions
